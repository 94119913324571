let initiated = false
let gatewayWindow = null
let pollTimer

export default function useMultisafepay() {
  const implementation = {
    doAuthorize: async (
      checkout,
      iframeContainer,
      {
        onApprove: onApproveCallback,
        onChallenge: onChallengeCallback,
        onError: onErrorCallback,
      }
    ) => {
      const config = useRuntimeConfig()
      console.debug('Multisafepay doAuthorize....')

      try {
        const res = await $fetch(
          `/api/public/checkout/multisafepay/${checkout.payment.method}/create`,
          {
            method: 'POST',
            body: {
              checkoutId: checkout.id,
            },
          }
        )

        const paymentUrl = res.data.payment_url
        iframeContainer.innerHTML = `<iframe src="${paymentUrl}" style="width: 100%; height: 100%; border: none;"></iframe>`
        if (!initiated) {
          initiated = true
          window.addEventListener('message', (event) => {
            if (event.data === 'OK') {
              onApproveCallback(event.data.payload)
            } else {
              // TODO CONSULTAR ORDEN EN MULTISAFEPAY
              onErrorCallback(
                createError({
                  message: 'UNKNOWN_ERROR',
                  data: event.data,
                })
              )
            }
          })
        }
      } catch (error) {
        console.debug(error)
        onErrorCallback(createError({ message: error.message, data: error }))
      }
    },
  }

  return {
    implementation,
  }
}
